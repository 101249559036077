
import AppMixin from '~/mixins/App'
export default {
  name: 'BenefitsIndex',
  mixins: [AppMixin],
  data() {
    return {
      listBenefits: [
        {
          title: this.$t('benefits.freeTrial'),
          image: '/imgs/free-ads-account-14-days.png',
          webp: '/imgs/free-ads-account-14-days.webp',
        },
        // {
        //   title: this.$t('benefits.variousPayment'),
        //   image: '/imgs/various-payment-methods.png',
        //   webp: '/imgs/various-payment-methods.webp',
        // },
        {
          title: this.$t('benefits.supportEcomdy'),
          image: '/imgs/support-from-ecomdy-experts.png',
          webp: '/imgs/support-from-ecomdy-experts.webp',
        },
        {
          title: this.$t('benefits.greatDeals'),
          image: '/imgs/great-deals-available.png',
          webp: '/imgs/great-deals-available.webp',
        },
        {
          title: this.$t('benefits.worldwideTarget'),
          image: '/imgs/worldwide-target.png',
          webp: '/imgs/worldwide-target.webp',
        },
      ],
    }
  },

  methods: {
    goTo(url) {
      if (!url) {
        return
      }

      window.open(url, '_blank')
    },
  },
}
